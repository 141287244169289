"use client";

import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useAuth } from "@clerk/nextjs";
import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import type { InterviewStoryblok } from "@/component-types-sb";
import { usePostHog } from "posthog-js/react";

import { Headline } from "@/components/typography/Headline";
import { Avatar } from "@/components/ui/Avatar";
import { Divider } from "@/components/ui/Divider";
import { Slider } from "@/components/ui/Slider";
import { Card } from "@/components/ui/Card";
import { VideoSkeleton } from "@/components/ui/Skeleton";
import { RichText } from "@/components/typography/RichText";
import { formatDate } from "@/lib/utils/date";
import { useConsent } from "@/lib/hooks/useConsent";
import { useIntersectionObserver } from "@/lib/hooks/useIntersectionObserver";
import { getInterviews } from "@/lib/storyblok/getInterviews";

const MuxPlayer = dynamic(() => import("@mux/mux-player-react"), {
  ssr: false,
  loading: () => <VideoSkeleton className="w-full h-full" />,
});

type InterviewProps = {
  blok: InterviewStoryblok;
  createdAt: string;
};

const Interview = ({ blok, createdAt }: InterviewProps) => {
  const [interviews, setInterviews] = useState<InterviewStoryblok[]>([]);
  const { userId } = useAuth();
  const { statistics } = useConsent();
  const posthog = usePostHog();
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
    threshold: 0.5,
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await getInterviews({
        filter_query: {
          _uid: { not_in: blok._uid },
        },
      });
      setInterviews(data);
    };
    fetchData();
  }, [blok]);

  useEffect(() => {
    if (isIntersecting) {
      posthog.capture("sponsor view", { title: blok.title });
    }
  }, [isIntersecting, blok, posthog]);

  return (
    <div
      className="flex justify-between px-4 pt-8 pb-16 lg:pt-16 lg:pb-24 mx-auto max-w-screen-xl"
      {...storyblokEditable(blok)}
    >
      <article className="mx-auto w-full max-w-5xl format format-sm sm:format-base lg:format-lg format-blue">
        <header className="mb-4 lg:mb-6 not-format">
          <address className="flex items-center mb-6 not-italic">
            <div className="inline-flex items-center mr-3 text-sm text-gray-900">
              <Avatar logo size="large" className="mr-4" />
              <div>
                <p className="text-base text-gray-500">DIALETiCS</p>
                <p className="text-base text-gray-500">
                  <time dateTime={createdAt}>
                    {formatDate(new Date(createdAt))}
                  </time>
                </p>
              </div>
            </div>
          </address>
          <Headline as="h1" title={blok.title} className="mb-4 lg:mb-6" />
        </header>

        <p className="lead text-2xl mb-8">{blok.subtitle}</p>

        {blok.playback_id ? (
          <MuxPlayer
            streamType="on-demand"
            playbackId={blok.playback_id}
            metadataVideoTitle={blok.title}
            metadataViewerUserId={userId || undefined}
            preload="auto"
            className="aspect-video mb-8"
            disableCookies={!statistics}
            accentColor="#fbc02d"
          />
        ) : (
          <Image
            src={blok.image?.filename || ""}
            alt={blok.image?.alt || ""}
            width={1280}
            height={720}
            className="mb-4"
          />
        )}

        <RichText html={renderRichText(blok.text)} />

        <Divider label="Sponsor" className="py-8" />

        <div ref={ref}>
          {blok.logo?.filename && (
            <Link
              href={blok.link?.url || "#"}
              target={blok.link?.target || "_self"}
            >
              <Image
                src={blok.logo.filename}
                alt={blok.logo.alt || ""}
                width={300}
                height={300}
                className="float-end ml-4 mb-4 w-full sm:w-auto"
              />
            </Link>
          )}
          <RichText html={renderRichText(blok.sponsor)} />
        </div>

        <Divider label="Weitere Interviews" className="pt-16" />

        <Slider id="interview-slider">
          {interviews?.map((nestedBlok) => (
            <Card
              key={nestedBlok.id}
              title={nestedBlok.content?.title}
              src={nestedBlok.content?.image?.filename}
              href={`/${nestedBlok.full_slug}`}
              showPlayIcon
            />
          ))}
        </Slider>
      </article>
    </div>
  );
};

export default Interview;
