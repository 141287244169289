import type { HTMLAttributes } from "react";

import type { TestimonialStoryblok } from "@/component-types-sb";
import { storyblokEditable } from "@storyblok/react/rsc";
import { cn } from "@/lib/cn";

import { Tagline } from "@/components/typography/Tagline";
import Testimonial from "./Testimonial";

type TestimonialSectionProps = {
  blok: TestimonialStoryblok;
} & HTMLAttributes<HTMLDivElement>;

const TestimonialSection = ({ blok, ...rest }: TestimonialSectionProps) => (
  <section
    className="bg-white py-24 relative isolate dark:bg-black overflow-hidden"
    {...storyblokEditable(blok)}
  >
    <div
      className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
      aria-hidden="true"
    >
      <div
        className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-brand-primary to-yellow-200"
        style={{
          clipPath:
            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
        }}
      />
    </div>
    <div
      className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
      aria-hidden="true"
    >
      <div
        className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-brand-primary to-yellow-200 xl:ml-0 xl:mr-[calc(50%-12rem)]"
        style={{
          clipPath:
            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
        }}
      />
    </div>
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-xl text-center">
        <Tagline text={blok.tagline} />

        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
          {blok.headline}
        </p>
      </div>
      <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
        <div
          className={cn(
            "-mt-8 grid sm:-mx-4 sm:grid-col-2 sm:text-[0] lg:grid-cols-3",
            rest.className
          )}
        >
          {blok.items?.map((nestedBlok: any) => (
            <Testimonial blok={nestedBlok} key={nestedBlok.uuid} />
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default TestimonialSection;
