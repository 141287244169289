import Link from "next/link";
import Image from "next/image";
import type { ComponentProps } from "react";
import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { cn } from "@/lib/cn";

// import type { AssetStoryblok } from "@/component-types-sb";

const fallbackImage = "/d.svg";

type CardProps = {
  title: string;
  subtitle?: string;
  src?: string;
  playbackId?: string;
  imageType?: "thumbnail" | "animated";
  href: string;
  showPlayIcon?: boolean;
} & ComponentProps<"div">;

const Card = ({
  title,
  subtitle,
  src = fallbackImage,
  playbackId,
  imageType = "thumbnail",
  href,
  showPlayIcon = false,
  ...rest
}: CardProps) => {
  const muxImage =
    imageType === "thumbnail"
      ? `https://image.mux.com/${playbackId}/thumbnail.webp?fit_mode=stretch&height=223&start=24`
      : imageType === "animated"
      ? `https://image.mux.com/${playbackId}/animated.webp?fps=15&height=223&start=24`
      : src;

  return (
    <div
      className={cn(
        "w-full max-w-xs overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800",
        rest.className
      )}
    >
      <Link href={href} aria-label="Mehr Informationen zu diesem Kurs">
        <Image
          src={!playbackId ? src : muxImage}
          alt=""
          width={320}
          height={224}
          className={cn("w-full h-56 object-cover", {
            ["object-contain"]: src === fallbackImage,
          })}
          priority={false}
        />
      </Link>

      <div className="py-5 px-2.5 text-center flex flex-row gap-2 items-center justify-between">
        {showPlayIcon && (
          <PlayCircleIcon className="w-6 h-6 text-gray-700 flex-shrink-0" />
        )}

        <Link
          href={href}
          className="block truncate overflow-clip text-sm md:text-xl font-bold text-gray-800 dark:text-gray-200"
        >
          {title}
        </Link>
        <span className="text-sm text-gray-700 dark:text-gray-300">
          {subtitle}
        </span>
      </div>
    </div>
  );
};

export { Card };
