"use client";

import { storyblokEditable } from "@storyblok/react/rsc";
import { useUser } from "@clerk/nextjs";
import type { UserResource } from "@clerk/types";

import { Headline } from "@/components/typography/Headline";
import { Tagline } from "@/components/typography/Tagline";
import { Slider } from "@/components/ui/Slider";
import { Card } from "@/components/ui/Card";
import type { VideoCollectionSliderStoryblok } from "@/component-types-sb";

type VideoCollectionSliderPops = {
  blok: VideoCollectionSliderStoryblok;
};

const hasPermission = (blok: any, user?: UserResource | null) =>
  !blok.content.roles ||
  blok.content.roles?.includes(user?.publicMetadata.role);

const VideoCollectionSlider = ({ blok }: VideoCollectionSliderPops) => {
  const { user } = useUser();

  return (
    <div
      className="mx-auto max-w-7xl px-6 lg:px-8 py-12"
      {...storyblokEditable(blok)}
    >
      <Tagline text="Videos" />
      <Headline as="h2" title={blok.headline} />
      <p className="mt-3 text-lg leading-8 text-gray-600">{blok.text}</p>

      <Slider id="video-collection-slider">
        {blok.items
          ?.filter((nestedBlok: any) => hasPermission(nestedBlok, user))
          .map((nestedBlok: any) => (
            <Card
              key={nestedBlok.id}
              title={nestedBlok.content?.title}
              // imageType="thumbnail"
              // playbackId={nestedBlok.content.playback_id}
              src={nestedBlok.content?.image?.filename}
              href={nestedBlok.full_slug}
              showPlayIcon
            />
          ))}
      </Slider>
    </div>
  );
};

export default VideoCollectionSlider;
