import { ComponentPropsWithoutRef, ReactNode } from "react";
import { ChevronDown } from "lucide-react";

import { cn } from "@/lib/cn";

type AccordionProps = ComponentPropsWithoutRef<"details"> & {
  title: string;
  children: ReactNode;
};

const Accordion = ({ title, children, ...rest }: AccordionProps) => (
  <details
    {...rest}
    className={cn(
      "group relative w-full shadow-md rounded-md bg-white",
      rest.className
    )}
  >
    <summary className="flex gap-x-2 cursor-pointer list-none items-center justify-between py-2 px-4 focus:outline-none">
      <span className="select-none font-semibold">{title}</span>
      <ChevronDown className="transition-transform group-open:rotate-180" />
    </summary>

    <div className="border-t-2 border-gray-100 leading-6 p-4">{children}</div>
  </details>
);

export { Accordion };
