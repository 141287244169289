import { storyblokEditable } from "@storyblok/react/rsc";

import { Headline } from "@/components/typography/Headline";
import { Tagline } from "@/components/typography/Tagline";
import { Slider as SliderComponent } from "@/components/ui/Slider";
import { Card } from "@/components/ui/Card";
import type { SliderStoryblok } from "@/component-types-sb";

type SliderPops = {
  blok: SliderStoryblok;
};

const Slider = ({ blok }: SliderPops) => {
  return (
    <div
      className="mx-auto max-w-7xl px-6 lg:px-8 py-12"
      {...storyblokEditable(blok)}
    >
      <Tagline text={blok.tagline} />
      <Headline as="h2" title={blok.headline} />
      <p className="mt-3 text-lg leading-8 text-gray-600">{blok.text}</p>

      <SliderComponent id="lesson-slider">
        {blok.items?.map((nestedBlok: any) => (
          <Card
            key={nestedBlok.id}
            title={nestedBlok.name}
            subtitle={nestedBlok.content?.chapter}
            // imageType="thumbnail"
            // playbackId={nestedBlok.content.playback_id}
            src={nestedBlok.content?.image?.filename}
            href={`/${nestedBlok.full_slug}`}
            showPlayIcon
          />
        ))}
      </SliderComponent>
    </div>
  );
};

export default Slider;
