"use client";

import Image from "next/image";
import { renderToStaticMarkup } from "react-dom/server";
import { useEffect, useState } from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import type { ArticleStoryblok } from "@/component-types-sb";

import { Headline } from "@/components/typography/Headline";
import { Divider } from "@/components/ui/Divider";
import { Slider } from "@/components/ui/Slider";
import { Card } from "@/components/ui/Card";
import { RichText } from "@/components/typography/RichText";
import Cta from "@/components/storyblok/Cta";
import InfoBox from "@/components/storyblok/InfoBox";
import ImageTeaser from "@/components/storyblok/ImageTeaser";
import TextImage from "@/components/storyblok/TextImage";
import TextContent from "@/components/storyblok/TextContent";
import Accordion from "@/components/storyblok/Accordion";
import Testimonial from "@/components/storyblok/Testimonial";
import TestimonialSection from "@/components/storyblok/TestimonialSection";
import { formatDate } from "@/lib/utils/date";
import { getArticles } from "@/lib/storyblok/getArticles";

type ArticleProps = {
  blok: ArticleStoryblok;
  createdAt: string;
};

const Article = ({ blok, createdAt }: ArticleProps) => {
  const [articles, setArticles] = useState<ArticleStoryblok[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getArticles({
        filter_query: {
          _uid: { not_in: blok._uid },
        },
      });
      setArticles(data);
    };
    fetchData();
  }, [blok]);

  return (
    <div
      className="flex flex-col justify-between px-4 pt-8 pb-16 lg:pt-16 lg:pb-24 mx-auto max-w-screen-xl"
      {...storyblokEditable(blok)}
    >
      <article className="mx-auto w-full max-w-3xl format format-sm sm:format-base lg:format-lg format-blue">
        <header className="mb-4 lg:mb-6 not-format">
          <p className="mb-2 text-base text-gray-500">
            <time dateTime={createdAt}>{formatDate(new Date(createdAt))}</time>
          </p>
          <Headline as="h1" title={blok.title} className="mb-4 lg:mb-6" />
        </header>

        <p className="lead text-2xl mb-8">{blok.subtitle}</p>

        {blok.image?.filename && (
          <Image
            src={blok.image.filename}
            alt={blok.image.alt || ""}
            width={1280}
            height={720}
            className="mb-4"
          />
        )}

        <RichText
          html={renderRichText(blok.text, {
            resolver: (component, blok) => {
              switch (component) {
                case "cta":
                  return renderToStaticMarkup(
                    <div className="not-prose max-w-xl mx-auto my-8 text-center">
                      <Cta blok={blok} />
                    </div>
                  );
                case "info_box":
                  return renderToStaticMarkup(<InfoBox blok={blok} />);
                case "image_teaser":
                  return renderToStaticMarkup(
                    <div className="not-prose">
                      <ImageTeaser blok={blok} />
                    </div>
                  );
                case "text_image":
                  return renderToStaticMarkup(
                    <div className="not-prose">
                      <TextImage blok={blok} />
                    </div>
                  );
                case "text_content":
                  return renderToStaticMarkup(
                    <div className="not-prose">
                      <TextContent blok={blok} />
                    </div>
                  );
                case "accordion":
                  return renderToStaticMarkup(
                    <div className="not-prose">
                      <Accordion blok={blok} />
                    </div>
                  );
                case "testimonial":
                  return renderToStaticMarkup(
                    <div className="not-prose">
                      <Testimonial blok={blok} />
                    </div>
                  );
                case "testimonial_section":
                  return renderToStaticMarkup(
                    <div className="not-prose">
                      <TestimonialSection
                        blok={blok}
                        className="lg:grid-cols-2"
                      />
                    </div>
                  );
              }
            },
          })}
        />

        {blok.sponsor?.length && (
          <>
            <Divider label="Sponsor" />

            {blok.logo?.filename && (
              <Image
                src={blok.logo.filename}
                alt={blok.logo.alt || ""}
                width={200}
                height={200}
                className="float-end ml-4 mb-4"
              />
            )}

            <RichText html={renderRichText(blok.sponsor)} />
          </>
        )}
      </article>

      <Divider label="Weitere Artikel" className="pt-16" />

      <Slider id="article-slider">
        {articles?.map((nestedBlok) => (
          <Card
            key={nestedBlok.id}
            title={nestedBlok.content?.title}
            src={nestedBlok.content?.image?.filename}
            href={`/${nestedBlok.full_slug}`}
          />
        ))}
      </Slider>

      <div className="mt-32">
        {blok.body?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  );
};

export default Article;
